export default {
    siteTitle: 'Skala-Podilskiy spetskarier',
    index: 'Home',
    about: 'About',
    products: 'Products',
    logistics: 'Logistics',
    reporting: 'Reporting',
    contacts: 'Contacts',
    requestPrice: 'Request price',
    logo: 'SPSK',
    copyright: '(©) 2019 Ltd. "Skala-Podilskiy spetskarier"',
    railwayTransportation: 'Railway transportation',
    autotransportations: 'Autotransportations (tanks)',
    pickUp: 'Pick up',
    otherProduct: 'OTHER PRODUCTS AND SERVICES(ONLY WITHIN 40 KM)',
    image: 'Image',
    writeToUs: 'Write to us',
    name: 'Name (Required)',
    phoneNumber: 'Phone Number (Required)',
    message: 'Message',
    submit: 'Submit',
    ceo: 'CEO',
    ceoName: 'Shashkina Nataliia Vasylivna',
    financialDepartment: 'Financial Department',
    financialDepartmentName: 'Mykhailov Mykola Vitaliiovych',
    supplyDepartment: 'Supply department',
    supplyDepartmentName: ' ',
    salesDepartment: 'Sales department',
    salesDepartmentName: ' ',
    bottomTitle:
        'Ltd. "Skala-podilskiy Spetskarier" delivers reliable and high-quality products of its own production from own raw materials last 70 years',
    logisticsTopTitle: 'Delivery of mineral powder to any point in Ukraine',
    productsTopTitle: 'Mineral powder (State Standard "ДСТУ Б В.2.7-121-2014")',
    quality: 'Quality',
    aboutTopTitle: 'Quality and reliability is tested by time',
    qualityСertificate: 'Quality certificate',
    year: 'year',
    ourHistory: 'Our History',
    notFound: 'Page Not Found',
    done: 'Your request has been sent',
    serverError: 'Error! Try again',
    sendError: 'Error! Check connection',
}
